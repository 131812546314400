body {
  background-color: #0d0d0d;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

@font-face {
  font-family: "Redland FREE";
  font-style: normal;
  font-weight: normal;
  src: local("Redland FREE"), url("RedlandFree-1GB2v.woff") format("woff");
}
